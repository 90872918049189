body {
  font-family: 'Open Sans', 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
}

:root {
  --azure: rgb(0, 145, 255);
  --brown-grey: rgb(124, 123, 123);
  --vermillion: rgb(255, 21, 0);
  --color-white: rgb(255, 255, 255);
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

h1 {
  font-size: 2.125rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.94px;
  text-align: center;
  color: var(--color-white);
  margin: 0;
}

h2 {
  font-size: 1.375rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.61px;
  color: var(--color-white);
  margin: 0;
}

h5 {
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: right;
  color: var(--color-white);
}

p {
  font-size: 1.125rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.61px;
  color: var(--color-white);
  margin: 0;
}

p + p {
  margin-bottom: 1rem;
}

p.caps {
  font-size: 1.125rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: var(--color-white);
  text-transform: uppercase;
}

p.secondary {
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--brown-grey);
}

.Alert {
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--vermillion);
}

.Subtitle {
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.5px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.Connection-lost-Style {
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.39px;
  text-align: center;
  color: var(--color-white);
}

.Enter-your-email-to-Style {
  font-size: 1.375rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.61px;
  text-align: center;
  color: var(--color-white);
}
