html,
body {
  margin: 0;
  background-color: black;
  color: white;
  font-family: 'Open Sans', 'Noto Sans JP', sans-serif;
  height: 100%;
  width: 100%;
  position: relative;
}

::placeholder {
  color: #3a3f4e;
}

input,
button {
  font-family: inherit;
}

.pointer {
  cursor: pointer;
  pointer-events: all;
}
